import { makeStyles } from '@material-ui/core/styles'
import { snapshotsQueries } from '../../networking'
import { useQuery } from 'react-query'
import LoadingSpinner from '../../components/LoadingSpinner'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Grid, Paper, Button, Tab, Tabs, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { InspectionAccordion } from './components/InspectionAccordion'
import dayjs from 'dayjs'
import BmsGraphic from './components/BMSGraphic'
import { conversions } from '../../utils'
import { useSnackBarStore } from '../../stateManagement'
import _ from 'lodash'
import { DotsLoader } from '../../components/DotsLoader/DotsLoader'
import InspectionsComparison from './components/InspectionsComparison'
import AlertDialog from '../../components/Dialogs/AlertDialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faScaleUnbalancedFlip } from '@fortawesome/free-solid-svg-icons'
import transitions from '@material-ui/core/styles/transitions'
import MethodologyPage from '../MethodologyPage'
import { useQueryProcedure } from '../../customHooks/queryHooks/procedureQueries'
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        background: theme.palette.background.default,
    },
    fab: {
        position: 'fixed',
        bottom: 20,
        right: 20,
    },
    tabs: { backgroundColor: theme.palette.background.paper },
    alert: {
        justifyContent: 'center',
        marginBottom: '50px',
        display: 'flex',
    },
    CdAValue: {
        fontSize: '1.1rem',
        padding: '2px 5px',
        borderRadius: theme.shape.borderRadius,
        textShadow: '0px 0px 4px rgb(0 0 0 / 20%)',
        color: theme.palette.common.white,
    },
    inspectionsContainerActive: {
        border: `2px solid ${theme.palette.secondary.main}`,
        borderRadius: '7px',
    },
}))
const tabs = Object.freeze({
    GRAFICO: 'GRAFICO',
    DATI: 'DATI',
})

export default function Bms() {
    const classes = useStyles()
    const { procedureId } = useParams()
    const { t } = useTranslation()
    const [currentTab, setCurrentTab] = React.useState(tabs.DATI)
    const [dataToDisplay, setDataToDisplay] = React.useState([])
    const [alertMessage, setAlertMessage] = React.useState('')
    const [isExpired, setIsExpired] = React.useState(false)
    const showSnackBar = useSnackBarStore((state) => state.show)
    const [nextInspection, setNextInspection] = React.useState(null)
    const [isOpenComparisonDialog, setIsOpenComparisonDialog] = React.useState(false) // TODO: delete me!
    const [isComparing, setIsComparing] = React.useState(false)
    const [isComparisonDialogOpen, setIsComparisonDialogOpen] = React.useState(false)
    const [checkedInspections, setCheckedInspections] = React.useState([])

    const { data: procedure, isLoading } = useQueryProcedure()
    const isBmsDisabled = !(procedure?.owner?.enabled_modules || []).includes('BMS')

    const { data: inspectionsList, refetch: refetchInspectionsList } = useQuery(
        [snapshotsQueries.getInspections.name],
        () => snapshotsQueries.getInspections.fn({ procedureId: procedureId, methodology: 'lgp' }),
        {
            onSuccess: (data) => {
                setDataToDisplay(formatData(data))
                if (dayjs().isAfter(nextInspection)) {
                    setIsExpired(true)
                } else {
                    setIsExpired(false)
                }
            },
            onError: (data) => {
                // console.log('onError', data)
                showSnackBar({
                    message: t('generic_error'),
                    severity: 'error',
                })
            },
        }
    )
    React.useEffect(() => {
        refetchInspectionsList()
    }, [inspectionsList])

    React.useEffect(() => {
        if (isExpired) {
            setAlertMessage({ message: 'Data scorsa ispezione:', severity: 'warning' })
        } else {
            setAlertMessage({ message: 'Data prossima ispezione:', severity: 'info' })
        }
    }, [isExpired])

    function formatData(data) {
        return data
            .slice()
            .reverse()
            .map((inspection) => ({
                date: dayjs(inspection.inspection_date).format('DD/MM/YYYY'),
                cda: conversions[inspection.data.result],
                strutturale_fondazionale:
                    conversions[inspection.data.result_extra_info.strutturale_fondazionale.value],
                sismica: conversions[inspection.data.result_extra_info.sismica.value],
                rischio_frane: conversions[inspection.data.result_extra_info.rischio_frane.value],
                rischio_idraulico:
                    conversions[inspection.data.result_extra_info.rischio_idraulico.value],
            }))
    }

    const handleComparisonButton = () => {
        setIsComparing(!isComparing)
        setCheckedInspections([])
    }

    const onChangeInspectionsChecked = (inspection, e) => {
        if (e.target.checked == true) {
            setCheckedInspections([...checkedInspections, inspection])
        } else if (e.target.checked == false) {
            setCheckedInspections(checkedInspections.filter((val) => val.id !== inspection.id))
        }
    }

    const onComparisonDialogClose = () => {
        setIsComparisonDialogOpen(false)
        setIsComparing(false)
        setCheckedInspections([])
    }

    React.useEffect(() => {
        if (checkedInspections.length === 2) {
            setIsComparisonDialogOpen(true)
        }
    }, [checkedInspections])

    React.useEffect(() => {
        if (!isComparisonDialogOpen && checkedInspections.length === 2)
            setTimeout(() => {
                setCheckedInspections([checkedInspections[0]])
            }, transitions.duration.leavingScreen)
    }, [isComparisonDialogOpen])

    return (
        <MethodologyPage>
            {isLoading || isBmsDisabled || !inspectionsList ? (
                <LoadingSpinner />
            ) : (
                <>
                    <Tabs value={currentTab} onChange={(event, value) => setCurrentTab(value)}>
                        <Tab value={tabs.DATI} label={t('dati')} className={classes.tabs} />
                        {inspectionsList.length != 0 && (
                            <Tab
                                value={tabs.GRAFICO}
                                label={t('grafico')}
                                className={classes.tabs}
                            />
                        )}
                    </Tabs>
                    {currentTab === tabs.DATI && (
                        <Paper elevation={0} className={classes.paper}>
                            <Grid
                                item
                                container
                                direction="column"
                                justifyContent="space-around"
                                alignItems="center"
                                spacing={3}
                            >
                                {/* TITOLO */}
                                <Grid item>
                                    <Typography
                                        variant="h4"
                                        component="h1"
                                        align="center"
                                        gutterBottom
                                        paragraph
                                    >
                                        {t('BMS')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {inspectionsList.length === 0 ? (
                                <Alert severity="info">{t('empty_inspectionsList')}</Alert>
                            ) : (
                                <>
                                    {/* DATA PROSSIMA ISPEZIONE */}
                                    <Alert
                                        className={classes.alert}
                                        severity={alertMessage.severity}
                                    >
                                        {alertMessage.message}{' '}
                                        {nextInspection ? (
                                            nextInspection
                                        ) : (
                                            <DotsLoader key={nextInspection} />
                                        )}
                                    </Alert>
                                    {/* TASTO COMPARAZIONE */}
                                    {inspectionsList.length >= 2 && (
                                        <Grid
                                            container
                                            justifyContent="flex-end"
                                            style={{ marginBottom: '50px' }}
                                        >
                                            <Grid item>
                                                <Button
                                                    onClick={handleComparisonButton}
                                                    variant="contained"
                                                    color="secondary"
                                                    startIcon={
                                                        <FontAwesomeIcon
                                                            style={{ marginRight: '5px' }}
                                                            icon={faScaleUnbalancedFlip}
                                                        />
                                                    }
                                                    style={{ width: 160 }}
                                                >
                                                    {isComparing ? t('comparing') : t('compare')}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {/* LISTA ISPEZIONI */}
                                    <Grid
                                        item
                                        className={
                                            isComparing && classes.inspectionsContainerActive
                                        }
                                    >
                                        {inspectionsList.map((inspection) => (
                                            <InspectionAccordion
                                                key={inspection.id}
                                                inspection={inspection}
                                                isComparing={isComparing}
                                                inspectionsChecked={(e) => {
                                                    onChangeInspectionsChecked(
                                                        {
                                                            inspection_date:
                                                                inspection.inspection_date,
                                                            id: inspection.id,
                                                        },
                                                        e
                                                    )
                                                }}
                                                isChecked={
                                                    !!_.find(checkedInspections, {
                                                        inspection_date: inspection.inspection_date,
                                                    })
                                                }
                                                isExtraordinary={
                                                    inspection.extraordinary_inspection
                                                }
                                            />
                                        ))}
                                    </Grid>
                                </>
                            )}
                        </Paper>
                    )}{' '}
                    {currentTab === tabs.GRAFICO && <BmsGraphic data={dataToDisplay} />}
                    {/*COMPARISON DIALOG*/}
                    <AlertDialog
                        open={isComparisonDialogOpen && checkedInspections.length === 2}
                        setIsOpen={onComparisonDialogClose}
                        text={t('compare_inspections_alert_text', {
                            inspection_1_date: dayjs(checkedInspections[0]?.inspection_date).format(
                                'DD/MM/YYYY'
                            ),
                            inspection_2_date: dayjs(checkedInspections[1]?.inspection_date).format(
                                'DD/MM/YYYY'
                            ),
                        })}
                        primaryButton={'Conferma'}
                        onSubmitCb={() => setIsOpenComparisonDialog(true)}
                    />
                    <InspectionsComparison
                        open={isOpenComparisonDialog}
                        inspections={[
                            _.get(checkedInspections, [0, 'id']),
                            _.get(checkedInspections, [1, 'id']),
                        ]}
                        onClose={() => {
                            setIsOpenComparisonDialog(false)
                            onComparisonDialogClose()
                        }}
                    />
                </>
            )}
        </MethodologyPage>
    )
}

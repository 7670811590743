import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core"
import { Box, Divider, Typography, Grid, Link } from "@material-ui/core";
import moment from "moment";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";

import ThresholdsCrossCharts from "./ThresholdsCrossCharts";
import ModalCrossCharts from "./ModalCrossCharts";

import analysisResults from "./analysis_result_example.json";
import CalendarRange from "./UI/Calendar";
import MyDataGrid from "./UI/DataGrid";

const StyledLink = Link

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

function AnalysisResults() {
  const { id, infrastructureId, resultsId } = {id: "proj0", infrastructureId: "civ.14-16", resultsId: "63e6771a21d4bcb796f963b8" };
  const [resoultFound, setResoultFound] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredModalResults, setFilteredModalResults] = useState([]);
  const [filteredThresholdResults, setFilteredThresholdResults] = useState([]);

  const handleStartDateChange = (date) => {
    console.log({date})
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  useEffect(() => {
    const list = analysisResults.filter(
      (result) => result.analisi_id === resultsId
    );
    setResoultFound(list);
  }, [resultsId]);
  console.log(resoultFound)

  useEffect(() => {
    console.log(`dates: `)
    console.log(
      resoultFound.map(result => dayjs(result.execution_date))
    )
    const filteredList = resoultFound.filter(
        (result) =>
            !startDate ||
            !endDate ||
            dayjs(result.execution_date).isBetween(
                dayjs(new Date(`${startDate}`)).startOf('day'),
                dayjs(new Date(`${endDate}`)).endOf('day'),
                null,
                '[]'
            )
    )

    setFilteredModalResults(filteredList);
    setFilteredThresholdResults(filteredList);
  }, [resoultFound, startDate, endDate]);

  console.log(filteredModalResults)

  const renderResult = (params) => {
    return (
          <Button
            component={StyledLink}
            to={`/project/${id}/projectInfrastructure/${infrastructureId}/analysisType/${params.row.id}/analysisDetail`}
            variant="outlined" >
              Dettagli
          </Button>
    );
  };
  const formatDate = (dateString) => {
    return moment.utc(dateString).format("DD/MM/YYYY - HH:mm:ss");
  };
  const columns = [
    {
      field: "execution_date",
      headerName: "Data",
      flex: 1,
      valueGetter: (params) => formatDate(params.row.execution_date),
    },
    {
      field: "action",
      headerName: "Azioni",
      flex: 0.5,
      align: "right",
      headerAlign: "right",
      sortable: false,
      disableColumnMenu: true,
      renderCell: renderResult,
    },
  ];

  return (
    <Grid container spacing={2}>
     <Grid item sx={{ width: "100%" }} >
       <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 1 }} >
         <Typography variant='h4' fontWeight={700} >Risultati Analisi</Typography>
         <Typography variant='subtitle2' fontWeight={400} >Cross Charts and Details</Typography>
       </Box>
     
       <Divider variant="middle" sx={{ py: 2 }} />
     
       <Grid item display="flex" sx={{ mb: 10 }} >
         <CalendarRange
           startDate={startDate}
           endDate={endDate}
           handleEndDateChange={handleEndDateChange}
           handleStartDateChange={handleStartDateChange}
         />
       </Grid>
     
       {resoultFound &&
         resoultFound.some((result) => result.id === "result_analysis_modale_1") && (
           <ModalCrossCharts filteredResoultFound={filteredModalResults} />
         )}
       {resoultFound &&
         resoultFound.some((result) => result.id === "result_analysis_soglie_1") && (
           <ThresholdsCrossCharts filteredResoultFound={filteredThresholdResults} />
         )}
     
       <Grid item
         display="flex"
         justifyContent="center"
         alignItems="center">
         <Box
           width={"50%"}
           height={400}
     
         >
           <MyDataGrid
             columns={columns}
             rows={resoultFound.filter((result) =>
               startDate && endDate
                 ? dayjs(result.execution_date).isBetween(startDate, endDate, null, "[]")
                 : true
             )}
           />
         </Box>
       </Grid>
     </Grid>
    </Grid>
  );
}
export default AnalysisResults;

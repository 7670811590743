import React from 'react'
import { Grid, Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import OverviewSection from '../components/Procedures/OverviewSection'
import MethodologiesSection from '../components/Procedures/Methodologies/MethodologiesSection'
import LoadingSpinner from '../components/LoadingSpinner'
import { useQueryProcedure } from '../customHooks/queryHooks/procedureQueries'

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4),
    },
}))

export default function ProcedurePage() {
    const classes = useStyles()

    const { isLoading } = useQueryProcedure()

    return (
        <Container maxWidth="md" className={classes.container}>
            <Grid container direction="column" wrap="nowrap" spacing={3}>
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <OverviewSection />
                        <MethodologiesSection />
                    </>
                )}
            </Grid>
        </Container>
    )
}

import React from "react";
import { Box, Tooltip as ToolTip, IconButton } from "@material-ui/core";
import { Restore, ZoomIn, ZoomOut } from "@material-ui/icons";
import { ArrowLeftIcon } from "@material-ui/pickers/_shared/icons/ArrowLeftIcon";
import { ArrowRightIcon } from "@material-ui/pickers/_shared/icons/ArrowRightIcon";


export const chartZoomOptions = {
  pan: {
    enabled: true,
    mode: "x",
  },
  zoom: {
    wheel: {
      enabled: true,
    },
    pinch: {
      enabled: true,
    },
    mode: "x",
  },
};

export const createChartRefFunctions = (chartRef) => {
  return {
    onResetZoom: () => {
      chartRef.current.resetZoom();
    },
    onZoomPluse: () => {
      chartRef.current.zoom(1.1);
    },
    onZoomMinus: () => {
      chartRef.current.zoom(0.9);
    },
    onPanPluse: () => {
      chartRef.current.pan({ x: 100 }, undefined, "default");
    },
    onPanMinus: () => {
      chartRef.current.pan({ x: -100 }, undefined, "default");
    },
  };
};

const ZoomControls = ({
  onResetZoom,
  onZoomPluse,
  onZoomMinus,
  onPanPluse,
  onPanMinus,
}) => {
  // const theme = useTheme();
  // const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isLargeScreen = true

  return (
    <Box
      id="button-PSD-chart"
      style={{
        display: "flex",
        position: "relative",
        zIndex: 1
      }}
    >
      <ToolTip title="Reset Zoom">
        <IconButton size="large" onClick={onResetZoom}>
          <Restore sx={{ color: "text.secondary" }} />
        </IconButton>
      </ToolTip>
      {isLargeScreen &&
        <>
          <ToolTip title="Zoom +">
            <IconButton size="large" onClick={onZoomPluse}>
              <ZoomIn sx={{ color: "text.secondary" }} />
            </IconButton>
          </ToolTip>
          <ToolTip title="Zoom -">
            <IconButton size="large" onClick={onZoomMinus}>
              <ZoomOut sx={{ color: "text.secondary" }} />
            </IconButton>
          </ToolTip>
        </>
      }
      <ToolTip title="Left">
        <IconButton size="large" onClick={onPanPluse}>
          <ArrowLeftIcon sx={{ color: "text.secondary" }} />
        </IconButton>
      </ToolTip>
      <ToolTip title="Right">
        <IconButton size="large" onClick={onPanMinus}>
          <ArrowRightIcon sx={{ color: "text.secondary" }} />
        </IconButton>
      </ToolTip>
    </Box>
  );
};

export default ZoomControls;

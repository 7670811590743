import React from "react";
import _ from "lodash";
import { capitalizeFirstLetter, removeDashFromString } from "../utils";
import { useLocation } from "react-router-dom";
import useMethodology from "./useMethodology";
import { useQueryProcedure } from "./queryHooks/procedureQueries";
import { useCompanyStore } from "../stateManagement";
import { useTranslation } from "react-i18next";

function useProcedureBreadcrumbs() {
    const {t} = useTranslation()
    const {pathname} = useLocation()
    const {data: procedure} = useQueryProcedure()
    const procedureId = procedure?.id
    const procedureName = procedure?.name
    const companyId = procedure?.owner?.id
    const companyName = procedure?.owner?.name
    const companies = useCompanyStore(state => state.companies)
    
    const {methodology} = useMethodology()
    
    const breadcrumbs = React.useMemo(() => {
        const isUserAllowed = !!companies.find(company => company.id === companyId)
        const path = pathname.split('/')
        
        let methodologyHomepage
        
        if (methodology === 'lgp') {
            methodologyHomepage = {
                name: methodology.toUpperCase(),
                link: `/procedures/${procedureId}/${methodology}`,
            }
        } else if (methodology === 'resisto') {
            methodologyHomepage = {
                name: methodology.toUpperCase(),
                link: `/procedures/${procedureId}/${methodology}/panoramica`,
            }
        } else if (methodology === 'dynamics') {
            methodologyHomepage = {
                name: methodology.toUpperCase(),
                link: `/procedures/${procedureId}/${methodology}`,
            }
        }
        
        let breadcrumbs = [
            {
                name: companyName,
                link: isUserAllowed ? `/companies/${companyId}` : undefined,
                forbidden: !isUserAllowed,
            },
            { name: procedureName, link: `/procedures/${procedureId}` },
            methodologyHomepage,
        ]
        
        if (methodology === 'lgp') {
            let level_index = path.findIndex((i) => RegExp(/^(livello-)\d+$/).test(i))
            let level
            if (level_index !== -1) {
                level = path[level_index].replace(/^(livello-)(\d+)$/, 'LA$2')
            } else if (path[path.length - 1] === 'bms') {
                level = 'BMS'
                level_index = path[path.length - 2]
            } else {
                level = 'LA2'
            }
            if (level !== 'LA2') {
                breadcrumbs.push({ name: 'Bozza' })
            }
            
            breadcrumbs.push({ name: level })
            
            const prepareName = (name) => _.capitalize(t(name.replaceAll('-', '_')))
            
            const rest_path = path.slice(level_index + 1)
            switch (rest_path.length) {
                case 1:
                    breadcrumbs.push({ name: prepareName(rest_path[0]) })
                    break
                case 2:
                    if (
                        rest_path[0] === 'frane-e-fenomeni-idraulici' &&
                        rest_path[1] === 'scheda'
                    ) {
                        rest_path[1] = 'scheda-descrittiva'
                    }
                    breadcrumbs.push({
                        name: prepareName(rest_path[0]),
                        link: `/procedures/${procedureId}/lgp/${path
                        .slice(level_index, level_index + 2)
                        .join('/')}`,
                    })
                    breadcrumbs.push({
                        name: prepareName(rest_path[1]),
                    })
                    break
                case 3:
                    breadcrumbs.push({
                        name: prepareName(rest_path[0]),
                        link: `/procedures/${procedureId}/lgp/${path
                        .slice(level_index, level_index + 2)
                        .join('/')}`,
                    })
                    breadcrumbs.push({
                        name: prepareName(rest_path[1]),
                        link: `/procedures/${procedureId}/lgp/${path
                        .slice(level_index, level_index + 3)
                        .join('/')}`,
                    })
                    breadcrumbs.push({ name: rest_path[2] })
                    break
                default:
            }
            
        }
        else if (methodology === 'resisto') {
            if (path.at(-2) === 'livelli') {
                breadcrumbs.push(
                    {
                        name: capitalizeFirstLetter(removeDashFromString(path[path.length - 2])),
                        link: `/procedures/${procedureId}/resisto/livelli`,
                    },
                    {
                        name: capitalizeFirstLetter(removeDashFromString(path[path.length - 1])),
                    }
                )
            } else {
                breadcrumbs.push({
                    name: capitalizeFirstLetter(removeDashFromString(path[path.length - 1])),
                })
            }
        }
        else if (methodology === 'dynamics') {
            let page = path.pop()
            let section = path.pop()
            // Verifico se è nella sezione Overview o in Configurations
            if (section === 'overview') {
                breadcrumbs.push({
                    name: t('dynamics_title'),
                })
                if (page === 'index') {
                    breadcrumbs.push({
                        name: t('dynamics_index'),
                        link: `/procedures/${procedureId}/dynamics/overview/index`,
                    })
                }
                if (page === 'analysis_list') {
                    breadcrumbs.push({
                        name: t('analysis_list'),
                        link: `/procedures/${procedureId}/dynamics/overview/analysis_list`,
                    })
                }
                if (page === 'analysis_result') {
                    breadcrumbs.push({
                        name: t('analysis_result'),
                        link: `/procedures/${procedureId}/dynamics/overview/analysis_result`,
                    })
                }
            } else if (section === 'configure') {
                breadcrumbs.push({
                    name: t('dynamics_configuration'),
                })
                if (page === 'infrastructure') {
                    breadcrumbs.push({
                        name: t('analysis_infrastructure'),
                        link: `/procedures/${procedureId}/dynamics/configure/infrastructure`,
                    })
                }
                if (page === 'analysis') {
                    breadcrumbs.push({
                        name: t('analysis_analysis'),
                        link: `/procedures/${procedureId}/dynamics/configure/analysis`,
                    })
                }
            }
        }
        return breadcrumbs
    }, [t, pathname, methodology, companies, companyId, companyName, procedureId, procedureName])
    
    return {breadcrumbs}

}

export default useProcedureBreadcrumbs
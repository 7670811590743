import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { Line } from "react-chartjs-2";
import ZoomControls from "../UI/ChartZoomControls";
import BoxCharts from "../UI/BoxCharts";

function ThresholdsErrorChart({
  errorDatasets,
  errorLabels,
  zoomOptions,
  chartRef,
  onResetZoom,
  onZoomPluse,
  onZoomMinus,
  onPanPluse,
  onPanMinus,
}) {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  const Error_config = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          // color: theme.palette.white.main,
        }
      },
      title: {
        display: true,
        text: "Errors Charts",
        // color: theme.palette.white.main,
      },
      zoom: zoomOptions,
    },
    scales:{
      x:{
        ticks:{
          // color: theme.palette.white.main,
        }
      },
      y:{
        ticks:{
          // color: theme.palette.white.main,
        }
      }
    }
  };
  // const Error_chart_data = {
  //   labels: errorLabels,
  //   datasets: errorDatasets
  // };

  useEffect(() => {
    setChartData({
      labels: errorLabels,
      datasets: errorDatasets
    });
  }, [errorLabels, errorDatasets]);

  return (
    <>
      <BoxCharts minHeight="380px" maxHeight="700px" >
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          padding: 3
        }} >
          <Typography variant='h5' color="text.secondary" fontWeight={600} >Grafico errori cross - soglie</Typography>
          <ZoomControls
            onResetZoom={onResetZoom}
            onZoomPluse={onZoomPluse}
            onZoomMinus={onZoomMinus}
            onPanPluse={onPanPluse}
            onPanMinus={onPanMinus}
          />
        </Box>

        <Line
          height={'100%'}
          options={Error_config}
          data={chartData}
          ref={chartRef}
        />
      </BoxCharts>
    </>
  );
}

export default ThresholdsErrorChart;

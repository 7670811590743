import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { Line } from "react-chartjs-2";
import ZoomControls from "../UI/ChartZoomControls";
// import { useTheme } from "@emotion/react";
import BoxCharts from "../UI/BoxCharts";

function ModalErrorChart({
  errorDatasets,
  dateLabels,
  zoomOptions,
  chartRef,
  onResetZoom,
  onZoomPluse,
  onZoomMinus,
  onPanPluse,
  onPanMinus,
}) {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  // const theme = useTheme();

  const Modal_Error_config = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          // color: theme.palette.white.main,
        }
      },
      title: {
        display: true,
        text: "Errors Charts",
        // color: theme.palette.white.main,
      },
      zoom: zoomOptions,
    },
    scales:{
      x:{
        ticks:{
          // color: theme.palette.white.main,
        }
      },
      y:{
        ticks:{
          // color: theme.palette.white.main,
        }
      }
    }
  };

  // const Modal_Error_chart_data = {
  //   labels: dateLabels,
  //   datasets: errorDatasets
  // };

  useEffect(() => {
    setChartData({
      labels: dateLabels,
      datasets: errorDatasets
    });
  }, [dateLabels, errorDatasets]);

  return (
    <>
      <BoxCharts minHeight="380px" maxHeight="700px" >
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          padding: 3
        }} >
          <Typography variant='h5' color="text.secondary" fontWeight={600} > Errori </Typography>
          <ZoomControls
            onResetZoom={onResetZoom}
            onZoomPluse={onZoomPluse}
            onZoomMinus={onZoomMinus}
            onPanPluse={onPanPluse}
            onPanMinus={onPanMinus}
          />
        </Box>
        <Line
          height={'100%'}
          options={Modal_Error_config}
          data={chartData}
          ref={chartRef}
        />
      </BoxCharts>
    </>
  );
}

export default ModalErrorChart;

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Container, Grid, Typography, Breadcrumbs } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { DotsLoader } from '../components/DotsLoader/DotsLoader'
import Alert from '@material-ui/lab/Alert'
import LGPToolbar from '../components/Procedures/Methodologies/LGPToolbar'
import ResistoToolbar from '../components/Procedures/Methodologies/ResistoToolbar'
import { useQueryProcedure } from '../customHooks/queryHooks/procedureQueries'
import DynamicsToolbar from '../components/Procedures/Methodologies/DynamicsToolbar'
import useMethodology from "../customHooks/useMethodology";
import useProcedureBreadcrumbs from "../customHooks/useProcedureBreadcrumbs";
import LoadingSpinner from "../components/LoadingSpinner";

const useStyles = makeStyles((theme) => ({
    outerContainer: {
        height: 'calc(100vh - 64px)',
        overflow: 'hidden',
    },
    rightContainer: {
        flex: 1,
        overflow: 'auto',
    },
    levelContainer: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4),
    },
    breadcrumbs: {
        'min-height': 32,
        marginBottom: 20,
        display: 'inline-flex',
        alignItems: 'center',
        color: theme.palette.text.secondary,
        '& a:hover': {
            color: theme.palette.text.primary,
        },
    },
    currentBreadcrumb: {
        color: theme.palette.text.primary,
        cursor: 'default',
    },
    disabledBreadcrumb: {
        cursor: 'not-allowed',
    },
    permissionInfo: {
        marginBottom: '20px',
    },
}))


export default function MethodologyPage({ children, maxWidth = 'md', alert = false }) {
    const classes = useStyles()
    const { t } = useTranslation()
    const { isLoading } = useQueryProcedure()
    const {methodology} = useMethodology()
    const {breadcrumbs} = useProcedureBreadcrumbs()
    return (
        <Grid container wrap="nowrap" className={classes.outerContainer}>
            <Grid item style={{ overflow: 'auto' }}>
                {methodology === 'lgp' && <LGPToolbar />}
                {methodology === 'resisto' && <ResistoToolbar />}
                {methodology === 'dynamics' && <DynamicsToolbar />}
            </Grid>
            <Grid item className={classes.rightContainer}>
                <Container maxWidth={maxWidth} className={classes.levelContainer}>
                    {isLoading ? <LoadingSpinner /> : (
                        <Breadcrumbs
                            separator={<NavigateNextIcon fontSize="small" />}
                            aria-label="breadcrumb"
                            className={classes.breadcrumbs}
                        >
                            {breadcrumbs.map((item, index) =>
                                item?.name ? (
                                    item.link ? (
                                        <Link
                                            key={item.name}
                                            to={item.link}
                                            className={[
                                                index === breadcrumbs.length - 1
                                                    ? classes.currentBreadcrumb
                                                    : undefined,
                                            ].join(' ')}
                                        >
                                            {item.name}
                                        </Link>
                                    ) : (
                                        <Typography
                                            key={item.name}
                                            className={[
                                                item.forbidden
                                                    ? classes.disabledBreadcrumb
                                                    : undefined,
                                                index === breadcrumbs.length - 1
                                                    ? classes.currentBreadcrumb
                                                    : undefined,
                                            ].join(' ')}
                                        >
                                            {item.name}
                                        </Typography>
                                    )
                                ) : (
                                    <DotsLoader key={index} />
                                )
                            )}
                        </Breadcrumbs>
                    )}
                    {alert && (
                        <Grid item className={classes.permissionInfo}>
                            <Alert severity="info">{t('missing_write_permission')}</Alert>
                        </Grid>
                    )}
                    {children}
                </Container>
            </Grid>
        </Grid>
    )
}

import React from 'react';
import { Box } from '@material-ui/core';
// import { useTheme } from '@emotion/react';

const BoxCharts = ({ minHeight, maxHeight, children }) => {
  // const theme = useTheme();
  
  return (
    <Box
      style={{
        // background: theme.palette.gradient.main,
        borderRadius: "8px",
        minHeight: minHeight,
        maxHeight: maxHeight,
        paddingBottom: 1
      }}
    >
      {children}
    </Box>
  );
}

export default BoxCharts;

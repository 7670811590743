import React, { useState, useEffect, useRef } from "react";
import { chartZoomOptions, createChartRefFunctions } from "./UI/ChartZoomControls";
// import {format} from "date-fns";
import moment from "moment";
import { Divider, Grid } from "@material-ui/core";
import ScopeDamageIndex from "./Charts/ScopeDamageIndex";
import GlobalDamageIndex from "./Charts/GlobalDamageIndex";
import ModalErrorChart from "./Charts/ModalErrorChart";

function ModalCrossCharts({ filteredResoultFound }) {
  const scopeDamageRefInstance = useRef(null);
  const globalDamageRefInstance = useRef(null);
  const modalErrorRefInstance = useRef(null);
  const scopeDamageRefFunctions = createChartRefFunctions(scopeDamageRefInstance);
  const globalDamageRefFunctions = createChartRefFunctions(globalDamageRefInstance);
  const modalErrorRefFunctions = createChartRefFunctions(modalErrorRefInstance);
  const [state, setState] = useState({
    errorDatasets: [],
    errorLabels: [],
    dateLabels: [],
    indiceDanno: [],
    semaforoLabels: [],
    semaforoData: [],
  });

  useEffect(() => {
    const dates = [];
    let indici_danno = [];
    const semafori = [];
    const errors = [];
    const errorColors = [
      { borderColor: "rgba(255, 0, 0, 1)", backgroundColor: "rgba(255, 0, 0, 0.2)" },
      { borderColor: "rgba(255, 199, 71, 1)", backgroundColor: "rgba(255, 199, 71, 0.2)" },
      { borderColor: "rgba(220, 320, 60, 1)", backgroundColor: "rgba(220, 320, 60, 0.2)" },
    ];
    console.log("prima", filteredResoultFound)

    filteredResoultFound.forEach((item, index) => {
      const date = moment.utc(item.result.data).format("DD/MM/YYYY HH:mm");
      dates.push(date);

      indici_danno = indici_danno.concat(item.result.indici_danno);
      semafori.push(item.result.semaforo);

    });
    /// creo oggetto per rinominare i tipi di errore
    const renameErrorType = {
      "Errore_num_canali": "Errore num. canali",
      "Errore_presenza_file": "Errore presenza file",
      "Errore_dati_incompleti": "Errore dati incompleti"
    };

    filteredResoultFound.forEach((item, index) => {
      if (item.error && index === 0) {
        item.error.tipi_di_errore.forEach((errorType, errorIndex) => {
          const errorsLabel = renameErrorType[errorType] || errorType;
          errors.push({
            label: errorsLabel,
            data: filteredResoultFound.map((item) => item.error ? item.error.valore_errori[errorIndex] : null),
            borderColor: errorColors[errorIndex].borderColor,
            backgroundColor: errorColors[errorIndex].backgroundColor,
          });
        });
        console.log(item.error.valore_errori, "errore");
      }
    });
    setState({
      errorDatasets: errors,
      errorLabels: dates,
      dateLabels: dates,
      indiceDanno: indici_danno,
      semaforoLabels: dates,
      semaforoData: semafori,
    });
  }, [filteredResoultFound]);


  return (
    <>
      <Grid
        item
        display="flex"
        flexDirection="column"
        gap={4}
      >
        <Grid item  >
          <ScopeDamageIndex
            dateLabels={state.dateLabels}
            indice_danno={state.indiceDanno}
            zoomOptions={chartZoomOptions}
            chartRef={scopeDamageRefInstance}
            onResetZoom={scopeDamageRefFunctions.onResetZoom}
            onZoomPluse={scopeDamageRefFunctions.onZoomPluse}
            onZoomMinus={scopeDamageRefFunctions.onZoomMinus}
            onPanPluse={scopeDamageRefFunctions.onPanPluse}
            onPanMinus={scopeDamageRefFunctions.onPanMinus}
          />
        </Grid>

        <Divider variant="middle"  />

        <Grid item >
          <GlobalDamageIndex
            semaforoLabels={state.semaforoLabels}
            semaforoData={state.semaforoData}
            zoomOptions={chartZoomOptions}
            chartRef={globalDamageRefInstance}
            onResetZoom={globalDamageRefFunctions.onResetZoom}
            onZoomPluse={globalDamageRefFunctions.onZoomPluse}
            onZoomMinus={globalDamageRefFunctions.onZoomMinus}
            onPanPluse={globalDamageRefFunctions.onPanPluse}
            onPanMinus={globalDamageRefFunctions.onPanMinus}
          />
        </Grid>

        <Divider variant="middle" />

        <Grid item >
          <ModalErrorChart
            dateLabels={state.dateLabels}
            errorDatasets={state.errorDatasets}
            zoomOptions={chartZoomOptions}
            chartRef={modalErrorRefInstance}
            onResetZoom={modalErrorRefFunctions.onResetZoom}
            onZoomPluse={modalErrorRefFunctions.onZoomPluse}
            onZoomMinus={modalErrorRefFunctions.onZoomMinus}
            onPanPluse={modalErrorRefFunctions.onPanPluse}
            onPanMinus={modalErrorRefFunctions.onPanMinus}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default ModalCrossCharts;

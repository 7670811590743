import { methodologies } from "../constants";
import { useLocation } from "react-router-dom";

const methodologyPattern = new RegExp(`(?<=^/procedures/.{24}/)(${methodologies.join('|')})(?=/|$)`)
function useMethodology() {
    const { pathname } = useLocation()
    const [methodology] = pathname.match(methodologyPattern) || []
    return {methodology}
}

export default useMethodology
import dayjs from 'dayjs'
import React from 'react'
import { getRuleCountByPath } from '../../FormConfigs/filter_model_config'
import useCompanyId from "../../customHooks/useCompanyId";
import { enelId } from "../../constants";

const cdaClasses = [
    { label: 'BASSA', field: 'bassa' },
    { label: 'MEDIOBASSA', field: 'mediobassa' },
    { label: 'MEDIA', field: 'media' },
    { label: 'MEDIOALTA', field: 'medioalta' },
    { label: 'ALTA', field: 'alta' },
]
const schema_statico_options = [
    { label: 'TRAVATE_APPOGGIATE', field: 'TRAVATE_APPOGGIATE' },
    { label: 'TRAVATE_CONTINUE_TELAIO', field: 'TRAVATE_CONTINUE_TELAIO' },
    { label: 'SOLETTA_APPOGGIATA', field: 'SOLETTA_APPOGGIATA' },
    { label: 'SOLETTA_INCASTRATA', field: 'SOLETTA_INCASTRATA' },
    { label: 'ARCO_MASSICCIO', field: 'ARCO_MASSICCIO' },
    { label: 'ARCO_SOTTILE', field: 'ARCO_SOTTILE' },
    { label: 'TRAVATE_GERBER_O_PONTI_STAMPELLA', field: 'TRAVATE_GERBER_O_PONTI_STAMPELLA' },
]
const impalcato_options = [
    { label: 'MURATURA', field: 'MURATURA' },
    { label: 'CA', field: 'CA' },
    { label: 'CAP', field: 'CAP' },
    { label: 'ACCIAIO', field: 'ACCIAIO' },
    { label: 'MISTO_CA_ACCIAIO', field: 'MISTO' },
    { label: 'LEGNO', field: 'LEGNO' },
]
const next_inspection_options = [
    { label: 'prossima_settimana', field: { number: 7, type: 'days' } },
    { label: 'prossimo_mese', field: { number: 1, type: 'month' } },
    { label: 'prossimi_3_mesi', field: { number: 3, type: 'month' } },
    { label: 'prossimi_6_mesi', field: { number: 6, type: 'month' } },
]
export const useQuickFilterProps = () => {
    const {companyId} = useCompanyId()
    const quickFilterProps = React.useMemo(() => {
        const quickFilterProps = [
            {
                title: `CDA complessiva (valutazione)`,
                chipProps: cdaClasses.map(({ label, field }) => ({
                    label,
                    path: 'methodologies.mit_guidelines.latest_result',
                    operatore: 'equals',
                    valore: field,
                })),
            },
            {
                title: `CDA complessiva (prevalutazione)`,
                chipProps: cdaClasses.map(({ label, field }) => ({
                    label,
                    path: 'methodologies.mit_guidelines.latest_prevalutazione_result',
                    operatore: 'equals',
                    valore: field,
                })),
            },
            {
                title: `CDA strutturale fondazionale (valutazione)`,
                chipProps: cdaClasses.map(({ label, field }) => ({
                    label,
                    path: 'methodologies.mit_guidelines.latest_result_extra_info.strutturale_fondazionale.value',
                    operatore: 'equals',
                    valore: field,
                })),
            },
            {
                title: `CDA strutturale fondazionale (prevalutazione)`,
                chipProps: cdaClasses.map(({ label, field }) => ({
                    label,
                    path: 'methodologies.mit_guidelines.latest_prevalutazione_result_extra_info.strutturale_fondazionale.value',
                    operatore: 'equals',
                    valore: field,
                })),
            },
            {
                title: `CDA sismica (valutazione)`,
                chipProps: cdaClasses.map(({ label, field }) => ({
                    label,
                    path: 'methodologies.mit_guidelines.latest_result_extra_info.sismica.value',
                    operatore: 'equals',
                    valore: field,
                })),
            },
            {
                title: `CDA sismica (prevalutazione)`,
                chipProps: cdaClasses.map(({ label, field }) => ({
                    label,
                    path: 'methodologies.mit_guidelines.latest_prevalutazione_result_extra_info.sismica.value',
                    operatore: 'equals',
                    valore: field,
                })),
            },
            {
                title: `Rischio idraulico`,
                chipProps: cdaClasses.map(({ label, field }) => ({
                    label,
                    path: 'methodologies.mit_guidelines.latest_result_extra_info.rischio_idraulico.value',
                    operatore: 'equals',
                    valore: field,
                })),
            },
            {
                title: `Rischio frane`,
                chipProps: cdaClasses.map(({ label, field }) => ({
                    label,
                    path: 'methodologies.mit_guidelines.latest_result_extra_info.rischio_frane.value',
                    operatore: 'equals',
                    valore: field,
                })),
            },
            {
                title: `Schema statico`,
                chipProps: schema_statico_options.map(({ label, field }) => ({
                    label,
                    path: 'methodologies.mit_guidelines.workspace.level1.schema_statico',
                    operatore: 'equals',
                    valore: field,
                })),
            },
            {
                title: `Impalcato`,
                chipProps: impalcato_options.map(({ label, field }) => ({
                    label,
                    path: 'methodologies.mit_guidelines.workspace.level0.impalcato.materiale_costruttivo.materiale',
                    operatore: 'equals',
                    valore: field,
                })),
            },
            {
                title: `Prossima ispezione`,
                chipProps: next_inspection_options.map(({ label, field }) => ({
                    label,
                    path: 'next_inspection_date',
                    operatore: 'lt',
                    valore: dayjs().add(field.number, field.type).format('YYYY-MM-DD'),
                })),
            },
            {
                title: `Warning`,
                chipProps: [
                    {
                        label: `Pregiudica statica`,
                        path: 'methodologies.mit_guidelines.workspace.level1.n_difetti_ps',
                        operatore: 'gt',
                        valore: 0,
                    },
                    {
                        label: 'Non valutato',
                        path: 'methodologies.mit_guidelines.latest_prevalutazione_result',
                        operatore: 'eq',
                        valore: null,
                    },
                    {
                        label: 'Presenza elementi critici',
                        path: 'methodologies.mit_guidelines.workspace.level1.elementi_critici.presenti',
                        operatore: 'eq',
                        valore: true,
                    },
                ],
            },
        ]
        if (companyId === enelId) {
            quickFilterProps.push({
                title: 'Accessibilità',
                chipProps: [
                    {
                        label: 'Carrabile',
                        path: 'methodologies.mit_guidelines.workspace.level0.rete_stradale.traffico_medio_giornaliero_intera_carreggiata.value',
                        operatore: 'gt',
                        valore: 0
                    },
                    {
                        label: 'Non carrabile',
                        path: 'methodologies.mit_guidelines.workspace.level0.rete_stradale.traffico_medio_giornaliero_intera_carreggiata.value',
                        operatore: 'eq',
                        valore: 0
                    },
                    {
                        label: 'Aperto al pubblico',
                        path: 'extra_fields.tipologia',
                        operatore: 'search',
                        valore: '^((?!(?<!non\\s)\\bintern[aeio]|non apert[aeio] al pubblico).)*$'
                    },
                    {
                        label: 'Non aperto al pubblico',
                        path: 'extra_fields.tipologia',
                        operatore: 'search',
                        valore: '(?<!non\\s)\\bintern[aeio]|non apert[aeio] al pubblico'
                    },
                ]
            })
        }
        return quickFilterProps
    }, [companyId])
    return {quickFilterProps}
}
export function getActiveFiltersProps(filters, t) {
    return (
        Object.keys(getRuleCountByPath(filters) || {})
            .sort((p, q) => p.localeCompare(q))
            // .map((path) => (
            //     _.get(filters?.rules, path, [])
            //     .sort((r, s) => String(r.valore).localeCompare(String(s.valore)))
            //     .sort((r, s) => r.operatore.toString().localeCompare(s.operatore.toString()))
            //     .map(rule => [path, rule])
            // ))
            // .flatMap((x) => x)
            // .map(([path, rule]) => ({
            //     path: path,
            //     operatore: rule.operatore,
            //     valore: rule.valore,
            //     key: `${path} ${rule.operatore} ${rule.valore}`,
            //     label: `${t(path.replace(/.*\./, ''))} ${t(rule.operatore)} ${t(String(rule.valore))}`,
            //     ruleEditingOptions: {rulesAreEqual: (rule) => true}
            // }))
            .map((path) => ({
                path: path,
                ruleEditingOptions: { rulesAreEqual: (rule) => true },
                label: path.match(/[^.]+\.?[^.]+(?=$|\.value)/)[0],
                onDelete: true,
            }))
    )
}

import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { fieldPcQueries, plcQueries, queryClient } from '../../../networking'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fab,
    Grid,
    Typography,
} from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'
import AppForm from '../../../components/AppForm'
import DisplayValidationErrors from '../DisplayValidationErrors'
import DynamicFormFields from '../../../components/FormItems/DynamicFormFields'
import {
    createSelect,
    replaceEmptyStringsWithNullWithin,
    textType,
} from '../../../FormConfigs/utils'
import SaveIcon from '@material-ui/icons/Save'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { yellow } from '@material-ui/core/colors'
import { useForm } from 'react-hook-form'
import { usePendingOperationsStore } from '../../../stateManagement'
import { AppChip } from "../../../components/QuickFilterChip";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        background: theme.palette.background.default,
    },
    fab: {
        position: 'fixed',
        bottom: 20,
        right: 20,
    },
    hide: {
        display: 'none',
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    analysis_catalog_table: {
        height: 400,
        width: '100%',
        backgroundColor: 'white',
    },
    default_analysis: {
        backgroundColor: yellow[700],
    },
}))
export default function PLC({ children, maxWidth = 'md' }) {
    const { t } = useTranslation()
    const classes = useStyles()

    const methods = useForm({
        mode: 'onBlur',
        defaultValues: {},
        shouldUnregister: false,
    })
    // leave them like this as per https://react-hook-form.com/api#formState
    const { isDirty, isValid } = methods.formState

    const [runPendingOps, clearPendingOps] = usePendingOperationsStore((state) => [
        state.runPendingOps,
        state.clearPendingOps,
    ])
    const { data: fieldPcData = [] } = useQuery(
        [fieldPcQueries.getFieldPc.name],
        fieldPcQueries.getFieldPc.fn
    )

    const { data: plcData = [] } = useQuery([plcQueries.getPLC.name], plcQueries.getPLC.fn)

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
        },
        {
            field: 'identifier',
            headerName: 'Identifier',
            width: 300,
        },
        {
            field: 'field_pc',
            headerName: 'Field PC',
            width: 150,
            renderCell: (params) => {
                return params.value.name
            },
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            renderCell: params => (
                <Button variant={'contained'} color={'secondary'} onClick={() => setIsOpenChangeFieldPc(params.id)}>{t('change_field_pc')}</Button>
            )
        }
    ]

    const [isOpenNewPLCForm, setIsOpenNewPLCForm] = React.useState(false)
    const [plcValidationErrors, setPlcValidationErrors] = React.useState()
    const fieldPcMutation = useMutation(
        (data) => plcQueries.createPLC.fn(replaceEmptyStringsWithNullWithin(data)),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([plcQueries.getPLC.name])
                setPlcValidationErrors([])
                handleCloseNewPlcForm()
            },
            onError: (error) => {
                setPlcValidationErrors(error.response.data)
            },
        }
    )
    
    const [isOpenChangeFieldPc, setIsOpenChangeFieldPc] = React.useState(false)
    const changeFieldPcMutation = useMutation(
        (data) => new Promise(() => console.log('changing field pc,', data))
    )
    

    function handleClickOpenNewPlcForm() {
        setIsOpenNewPLCForm(true)
    }

    function handleCloseNewPlcForm() {
        setIsOpenNewPLCForm(false)
    }

    function submitNewFiledPc(data) {
        if (isDirty && isValid) {
            runPendingOps().then(() => {
                fieldPcMutation.mutate(data['field_pc'])
            })
        }
    }

    return (
        <>
            <Grid item container direction="column" justify-conte="space-around" spacing={3}>
                <Grid item>
                    <Typography variant="h4" component="h1" align="center">
                        {t('admin.section_title')}
                    </Typography>
                    {t('admin.plc.description')}
                </Grid>
                <Grid item align="right">
                    <Button variant="contained" onClick={handleClickOpenNewPlcForm}>
                        {t('admin.plc.create_new_plc')}
                    </Button>
                </Grid>
                <Grid item xs={12} md={12}>
                    PLC Configurati
                    <div className={classes.analysis_catalog_table}>
                        <DataGrid
                            rows={plcData}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            disableSelectionOnClick
                        />
                    </div>
                </Grid>
            </Grid>
            <Dialog
                open={isOpenNewPLCForm}
                onClose={handleCloseNewPlcForm}
                aria-labelledby="form-plc"
                fullWidth={true}
                maxWidth="md"
            >
                <AppForm onSubmit={submitNewFiledPc} methods={methods}>
                    <DialogTitle id="form-analysis-catalog">PLC</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Configure new PLC.</DialogContentText>
                        <Grid item>
                            <DisplayValidationErrors validationErrors={plcValidationErrors} />
                            <DynamicFormFields
                                disableAll={false}
                                fieldsKey={'field_pc'}
                                formFields={{
                                    name: { ...textType, __meta: { required: true } },
                                    field_pc: {
                                        ...createSelect(
                                            fieldPcData.map((fieldPc) => {
                                                return { label: fieldPc.name, value: fieldPc.id }
                                            })
                                        ),
                                        __meta: { required: true },
                                    },
                                }}
                                formId={`form_plc`}
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Fab
                            variant="extended"
                            color="secondary"
                            type="submit"
                            disabled={!isDirty || !isValid}
                        >
                            {fieldPcMutation.isLoading ? (
                                <CircularProgress size={18} style={{ marginRight: 8 }} />
                            ) : (
                                <>
                                    <SaveIcon />
                                    {t('salva')}
                                </>
                            )}
                        </Fab>
                    </DialogActions>
                </AppForm>
            </Dialog>
            <Dialog
                open={!!isOpenChangeFieldPc}
                onClose={() => setIsOpenChangeFieldPc(false)}
                fullWidth={true}
                maxWidth="md"
            >
                <DialogContent>
                    <DialogContentText>
                        Ciao
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    )
}


import React from 'react';
// import { itIT } from '@mui/x-data-grid';
import { StyledDataGrid } from '../Theme/styledComponents';


function MyDataGrid({ rows, columns, ...props }) {
    return (
      <StyledDataGrid
        rows={rows}
        columns={columns}
        fullWidth
        // localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
        hideFooterSelectedRowCount
        initialState={{
          pagination: { paginationModel: { pageSize: 15 } },
        }}
        pageSizeOptions={[15, 25, 35, 45]}
        {...props}
      />
    );
  }

  export default MyDataGrid;
  